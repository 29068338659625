.zof-items-selection {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	button {
		cursor: pointer;
	}
	button.full {
		margin-bottom: 4px;
		width: 100%;
		display: block;
	}
}

.zof-items-selection-row {
	display: flex;
	.sort {
		flex-basis: 200px;
		p {
			font-size: .9em;
			text-align: center;
			margin: .5em 0;
		}
	}
	.filter {
		flex-basis: calc(100% - 200px - 2em);
		margin-right: 2em;
	}
}

.zof-input-row {
	display: flex;
	margin: 0 0 .5em 0;
	input[type="text"], select {
		flex: 1;
		order: 2;
		font-size: 1em;
		padding: .5em .6em;
		border: 1px solid #ccc;
	}
	button, span {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 2em;
		order: 1;
		background: none;
		border: 1px solid #ccc;
		border-right: none;
		color: #888;
		background: #eee;
	}
}






.zof-items-selection-category {
	margin-top: 0;
	margin-bottom: .5em;
}
.zof-items-selection-search {
	position: relative;
	margin-top: 0;
	margin-bottom: .5em;
	display: flex;
	input[type="text"] {
		order: 2;
		flex: 1;
		font-weight: bold;
		font-size: 1.05em;
		padding: .5em .6em .5em .6em;
	}
	button {
	}
	span {
		position: absolute;
		right: 2.25em;
		top: 4px;
	}
}
.zof-items-selection-sort {
	font-size: .85em;
	display: flex;
	// justify-content: space-between;
	align-items: center;
	margin: 0 0 1em 0;
	span {
		white-space: nowrap;
		padding-right: .5em;
	}
	select {
		max-width: 17em;
	}
}









.zof-items-list {
	flex: 1;
	overflow: hidden;
}




.zof-select-items {
	display: flex;
	flex-wrap: wrap;
}



.zof-search-result {
	flex-basis: calc(33.33% - .5em);
	flex-wrap: wrap;
	margin: 0 0 .5em .5em;
	>div {
		display: flex;
		position: relative;
		background: #fff;
		padding: 0;
		align-items: center;
		text-decoration: none;
		border: 1px solid #fff;
		cursor: pointer;
		.item-wrap {
			display: flex;
			width: 100%;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			transition: all .2s;
		}
		.image {
			position: relative;
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				display: block;
				width: 85%;
				height: auto;
			}
		}
		.image.undesirable {
			&:after {
				content: "STOP";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				background: #f00;
				color: #fff;
				font-size: 11px;
				padding: 1px;
				z-index: 50;
			}
		}
		.details {
			flex-grow: 1;
			margin-left: 1em;
			padding: 8px 0;
			h4 {
				font-size: 1em;
				font-weight: bold;
				margin: 0 0 4px 0;
				color: $primary;
			}
			p {
				font-size: .85em;
				margin: 0 0 4px 0;
				color: #444;
			}
			table {
				width: auto;
				font-size: .85em;
				td {
					padding-right: .5em;
					line-height: 100%;
				}
			}
		}
		.details.undesirable {
			h4 {
				color: #f00;
			}
		}
		.item-info-popup {
		}
		&:hover {
			.item-wrap {
			}			
		}
	}
	.undesirable-info-color {
		display: inline-block;
		width: 1em;
		height: 1em;
		text-align: center;
		line-height: 1em;
		border-radius: 3px;
		color: #fff;
		margin-left: 2px;
	}

	.item-food {
		flex-basis: calc(50% - 1em);
		margin: 0 1em 1em 0;
	}
}


.zof-recipe {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	height: 100%;
	.zof-recipe-contents {
		flex: 1;
		margin-bottom: 1em;
	}
	.zof-recipe-content {
		display: flex;
		.zof-recipe-items {
			flex-grow: 1;
		}
		.zof-recipe-evaluation {
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: 330px;
			width: 330px;
			min-height: 120px;
			height: 100%;
			font-size: .85em;
			line-height: 120%;
		}
	}
}





.zof-recipe-noflex {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: .5em .5em .5em 0;
}

.zof-recipe-title {
	display: flex;
	align-items: center;
	background: $primary;
	color: #fff;
	padding: .5em;
	border-radius: 3px;
	.zof-recipe-title-mealtype-title {
		margin-right: 1em;
	}
	.zof-recipe-title-back-btn {
		text-decoration: none;
		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		line-height: 1.5em;
		text-align: center;
		background: darken($primary, 10%);
		color: #fff;
		font-size: .85em;
		margin-right: .5em;
		border-radius: 100px;
		cursor: pointer;
		&:hover {
			background: #fff;
			color: $primary;
		}
	}
	p {
		display: block;
		margin: 0;
	}
	input[type="text"], .DayPickerInput input {
		padding: .4em .6em;
		border: none;
		color: #000;
	}
	input[type="text"] {
		width: 20em;
	}
	.DayPickerInput input {
		width: 10em;
	}
	aside {
		margin-left: auto;
		a {
			cursor: pointer;
			color: #fff;
			text-decoration: none;
			background: rgba(0,0,0,.15);
			padding: 4px 5px;
			border-radius: 3px;
			margin-left: .25em;
			&:hover {
				background: #fff;
				color: $primary-dark;
			}
			i {
				font-size: .85em;
			}
		}
		input[type="checkbox"] {
			display: none;
		}
		label {
			cursor: pointer;
			font-size: .85em;
			font-weight: 300;
			display: flex;
			align-items: center;
			&:before {
				content: "✔";
				display: block;
				margin-right: 3px;
			}
		}
		input[type="checkbox"]:checked ~ label {
			&:before {
				content: "✖";
			}
		}
	}
}


.zof-activities-list-wrap {
	padding-top: 1em;
}
.zof-activities-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	max-height: 240px;
}
.zof-activities-list-item {
	cursor: pointer;
	flex-basis: calc(50% - 1em);
	margin-right: 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.image {
		width: 45px;
		padding: 5px;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	.details {
		padding-left: 1em;
		flex: 1;
	}
	h4 {
		color: #444 !important;
		border-bottom: none !important;
		padding-bottom: 0 !important;
		font-size: .9em !important;
		margin: 0;
		font-weight: normal;
	}
	&:hover {
		background: #ffb;
	}
}


.zof-item-row {
	display: flex;
	position: relative;
	align-items: center;
	margin: 0 0 .5em 0;
	border-top: 1px solid #ddd;
	box-shadow: inset 0 8px 16px rgba(0,0,0,.025);
	background: #fff;

	&:last-child {
		margin-bottom: 0;
	}

	>div {
		margin-right: 1em;
		&:last-child {
			margin-right: 0;
		}
	}
	.options {
		display: flex;
		// align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		.weight-input {
			position: relative;
		}
		.weight-range-info {
			position: absolute;
			left: 0;
			bottom: 100%;
			width: auto;
			text-align: center;
			background: #6b6b6b;
			color: #fff;
			padding: 0 5px;
			border-radius: 3px;
			z-index: 1000;
			p {
				white-space: nowrap;
				margin: 5px 0;
			}
			p:first-child {
				font-size: 13px;
			}
			p:last-child {
				font-size: 11px;
				font-weight: 300;
			}
			span {
				font-size: 11px;
			}
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -6px;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 6px 0 6px;
				border-color: #6b6b6b transparent transparent transparent;
			}
		}
		.normalized-unit-info {
			padding: 4px 0 0;
			font-size: 11px;
			font-style: italic;				
		}		
		input[type="text"] {
			width: 5em;
			text-align: right;
			padding: .4em .6em;
			border: 1px solid #bbb;
			border-radius: 3px;
			box-shadow: inset 1px 2px 3px rgba(0,0,0,.15);
		}
		input[type="text"].invalid {
			border: 1px solid #f00;
			background: #f00;
			color: #fff;
		}
		span {
			margin: 0 1em 0 .5em;
		}
		select {
			margin-right: 1em;
		}
		label {
			margin-right: 1em;
			input {
				vertical-align: middle;
			}
		}
	}
	.modifications {
		min-width: 104px;
		a {
			cursor: pointer;
			color: #000;
			padding: .5em 1em;
			border-radius: 3px;
			span {
				margin-left: .1em;
				background: $primary;
				color: #fff;
				font-size: .9em;
				font-weight: bold;
				padding: 2px 4px;
				border-radius: 3px;
			}
			&:hover {
				background: #fff;
			}
		}
	}
	.modifications-selected {
		a {
			background: #fff;
			border-radius: 3px 3px 0 0;
			box-shadow: 0 4px 8px rgba(0,0,0,.15);
		}
	}
	.buttons {
		margin-left: auto;
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 20px;
		display: flex;
		flex-direction: column;
		a {
			color: #bbb;
			text-decoration: none;
			text-align: center;
			display: inline-block;
			width: 2em;
			height: 2em;
			line-height: 2em;
			font-size: 9px;
			text-align: center;
			&:hover {
				color: #222;
			}
			&.item-remove:hover {
				color: #f00;
			}
		}
	}
	.icon {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 80px;
		margin-right: .25em;
		//border-radius: 3px;		
		img {
			height: 85px;
			width: auto;
			display: block;
			position: relative;
			background: #fff;
			border-radius: 3px;
			padding: 10px;
			transition: all .15s;
			// border: 1px solid #ddd;
			/*
			&:hover {
				z-index: 500000;
				transform: translateX(34%) scale(1.7);
				transition: all .3s;
				box-shadow: 0 4px 8px rgba(0,0,0,.1);
			}
			*/
		}
	}
	.title {
		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 15em;
		padding: .5em 1em .5em .25em;
		h4 {
			font-size: 1.15em;
			margin: 0 0 .1em 0;
			font-weight: 600;
			color: $primary;
			a {
				display: inline-block;
				width: 1.2em;
				height: 1.2em;
				line-height: 1.35em;
				font-size: 12px;
				text-align: center;
				background: #999;
				color: #fff;
				text-decoration: none;
				border-radius: 100px;
				vertical-align: top;
				&:hover {
					background: $primary;
				}
			}
		}
		p {
			margin: 0;
			font-size: .85em;
			select {
				width: calc(100% - 2.5em);
				border: 1px solid #bbb;
				border-radius: 3px;
				padding: .25em .2em;
				cursor: pointer;
			}
		}
		select {
			cursor: pointer;
		}
	}
}






.food-item-row {
	display: flex;
	position: relative;
	align-items: center;
	padding: .25em 1em;
	background: #fff;
	&:not(:last-child) {
		border-bottom: 1px solid #e6e6e6;
	}
	.buttons {
		margin-left: auto;
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 20px;
		display: flex;
        flex-direction: column;
        visibility: hidden;
		a {
			color: #aaa;
			text-decoration: none;
			text-align: center;
			display: inline-block;
			width: 2em;
			height: 2em;
			line-height: 2em;
			font-size: 10px;
			text-align: center;
			&:hover {
				color: #222;
			}
			&.item-remove:hover {
				color: #f00;
			}
		}
	}
	.icon {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;
		img {
			width: 100%;
			height: auto;
			display: block;
			position: relative;
		}
	}
	.title {
		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 15em;
		padding: .5em 1em .5em 2em;
		h4 {
			font-size: 1.15em;
			margin: 0 0 .5em 0;
			font-weight: 400;
			color: #333;
			a {
				display: inline-block;
				width: 1.2em;
				height: 1.2em;
				line-height: 1.35em;
				font-size: 14px;
				text-align: center;
				background: #999;
				color: #fff;
				text-decoration: none;
				border-radius: 100px;
				vertical-align: top;
				&:hover {
					background: $primary;
				}
			}
			label {
				font-size: 14px;
				margin-left: 1.5em;
				input {
					vertical-align: middle;
				}
			}
		}
		p {
			margin: 0;
			font-size: .85em;
			select {
				width: calc(100% - 2.5em);
				border: 1px solid #bbb;
				border-radius: 3px;
				padding: .25em .2em;
				cursor: pointer;
			}
		}
		select {
			cursor: pointer;
		}
	}
	&:hover {
		background: #fafafa;
		.title {
			h4 {
			}
		}
        .buttons {
            visibility: visible;
        }
	}
}






.food-group {
	background: #fff;
	margin: 0 0 1em 0;
	border-radius: $radius;
	padding-left: 2px;
	position: relative;
	.food-group-header {
		display: flex;
		background: $background-light;
		padding: .5em;
		select, input[type="text"] {
            border: 1px solid #bbb;
			width: 15em;
			margin-right: .5em;
			padding-left: .5em;
			padding-right: .5em;
        }
        >input[type="text"] {
			flex: 1;
        }
		select {
			width: 6em;
		}
		.title {
			font-weight: 700;
		}

		.amount-change {
            align-self: center;
            display: inline-block;
			position: relative;
			label {
				cursor: pointer;
				padding: 0 1em 0 0;
				font-size: .9em;
				i {
					color: rgba(0,0,0,.4);
				}
				span {
					border-bottom: 1px solid rgba(0,0,0,.3);
				}
			}
			input[type="checkbox"] {
				display: none;
			}
			i {
				margin-left: 6px;
			}
			.amount-change-inner {
				display: none;
				position: absolute;
				left: 0;
				top: calc(100% + .5em);
				padding: 8px;
				background: #fff;
				z-index: 250;
				box-shadow: 0 4px 8px rgba(0,0,0,.15);
				align-items: center;
				span {
					margin: 0 .7em 0 .25em;
				}
				input[type="text"] {
					width: 3.5em;
					text-align: center;
					margin-right: 0;
				}
				button {
					background: none;
					border: none;
					padding: 0;
				}
			}
			input[type="checkbox"]:checked ~ .amount-change-inner {
				display: flex;
			}
		}

		a.toggle {
			background: $primary;
			color: #fff;
			padding: .5em;
			
		}

		
		/*a, i {
			text-decoration: none;
			display: inline-block;
            color: $primary-dark;
            align-self: center;
            padding: .25em .25em;
			cursor: pointer;
			&:hover {
				background: #fff;
				color: $primary;
			}
		}*/

		aside {
		//	margin-left: auto;
			display: flex;
			align-items: center;
			padding-right: 8px;
			a {
				padding: .5em;
				color: rgba(0,0,0,.4);
				&:hover {
					color: #000;
				}
			}
		}
	}
	.zof-food-group-body {
		padding: .5em;
	}
}

.zof-food-group-add-btn {
    display: flex;
    background: #f7f7f7;
    padding: 1em;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #777;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .75em;
        width: 1.75em;
        height: 1.75em;
        border: 1px solid #4eb32d;
        color: #4eb32d;
        border-radius: 100px;
        margin-right: .6em;
        background: #fff;
    }
    &:hover {
        background: #e3efde;
        color: #4eb32d;
        span {
            background: #4eb32d;
            color: #fff;
        }
    }
}












.item-info-icon {
	color: $primary;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	padding: .5em .5em .5em 0;
	cursor: pointer;
	&:hover {
		color: #000;
	}
}

.item-info-weight {
	display: flex;
	font-size: .85em;
	.item-weight-input {
		display: flex;
		input {
			width: 4em;
			text-align: left;
			background: #fff;
			border: 1px solid #ddd;
			border-right: none;
			&:focus {
				background: #ff7;
				outline: none;
			}
		}
		span {
			padding-left: .5em;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 .5em 0 .15em;
			background: #fff;
			border: 1px solid #ddd;
			border-left: none;
			margin-right: .5em;
		}
	}
	.item-weight-normalized {
		&:before {
			content: "(";
		}
		&:after {
			content: ")";
		}
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.item-info-modifications {
	display: flex;
	font-size: .9em;
	margin-top: 6px;
	.item-modifications-list {
		display: flex;
		flex-wrap: wrap;
		padding: .5em;
		.modification-option {
			display: flex;
			flex-basis: calc(25% - 1em - 1rem);
			align-items: center;
			margin: 0 1em 1.5em 0;
		}
		label {
			cursor: pointer;
			display: flex;
			flex-basis: calc(100% - 1.25rem);
			flex-direction: column;
			align-items: center;
			padding: 5px 0;
			white-space: nowrap;
			border: 1px solid #fff;
			position: relative;
			.icon {
				flex: 1;
			}
			img {
				height: 38px;
				width: auto;
				display: block;
				margin-right: .25em;
			}
			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}

		.link-help {
			margin-left: auto;
			display: inline-block;
			z-index: 20;
			width: 1rem;
			height: 1rem;
			position: relative;
			&:before {
			  content: "?";
			  background: none;
			  display: flex;
			  width: 1rem;
			  height: 1rem;
			  align-items: center;
			  justify-content: center;
			  color: #aaa;
			  font-size: 10px;
			  border: 1px solid #aaa;
			  border-radius: 50%;
			  z-index: 1;
			}  
		  }


		input {
			display: none;
		}
		i {
			display: none; // inline-block;
			margin-right: 2px;
			text-align: center;
			color: #bbb;
			&:before {
				content: "\f0c8";
			}
		}
		input:checked + label {
			border: 1px solid $primary;
			span {
				font-weight: bold;
			}
		}
		input:checked ~ i {
			color: $primary;
			&:before {
				content: "\f14a";
			}
		}
	}
}

.item-info-undesirable {
	display: flex;
	font-size: .85em;
	margin-top: 6px;
	color: #f00;
	.item-info-icon {
		background: none;
		color: #f00;
		padding: 0;
	}
	.item-info-undesirable-profiles {
	}
	span {
		font-size: .85em;
		margin-right: .5em;
		padding: 2px;
		font-weight: bold;
		text-transform: uppercase;
	}
}






.zof-food-detail {
	position: relative;
	>h2 {
		display: flex;
		align-items: center;
		color: $primary;
		font-size: 1.5em;
		margin: 0 0 .5em 0;
		button {
			background: #fff;
			border: 1px solid $primary;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 1.25em;
			height: 1.25em;
			font-size: 1.2em;
			margin-right: .35em;
			border-radius: 200px;
			cursor: pointer;
			&:hover {
				background: $primary;
				color: #fff;
			}
		}
	}
    >button {
        font-size: 1.25em;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #aaa;
        color: #777;
        width: 1.5em;
        height: 1.5em;
        border-radius: 100px;
    }
}
.zof-food-detail-add {
	margin: 0 0 1em 0;
	button {
		@include button_default();
		margin: 0 4px 4px 0;
		display: inline-flex;
		align-items: center;
		border: none;
		text-align: left;
		p {
			margin: 0;
			font-weight: bold;
			span {
				font-size: 10px;
				font-weight: normal;
			}
		}
		&:hover {
			i {
				color: #fff;
			}
		}
	}
}








.news {
	max-width: 720px;
	margin: 2em auto 2em auto;
	>h2 {
		text-align: center;
		font-size: 1.6em;
		margin: 1em 0 2em 0;
		font-weight: 100;
	}
	.news-item {
		padding: 1em 1em 1.5em 1em;
		margin-bottom: 1.5em;
		border-bottom: 1px solid #eee;
		font-size: .9em;
		line-height: 140%;
		p, li {
			line-height: 140%;
		}
		h4 {
			font-size: 1.3em;
			color: #4eb32d;
			margin: 0 0 .25em 0;
		}
		img {
			width: auto !important;
			max-width: 100% !important;
			height: auto !important;
			display: block;
		}
	}
}



.faq-row {
}

.faq-questions {
	.question {
		margin: 0 0 1em 0;
		border: 1px solid #ccc;
		border-radius: $radius*2;
		input {
			display: none;
		}
		label {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 1em;
			i {
				margin-right: .65em;
				font-size: 1.5em;
				color: #aaa;
			}
		}
		.answer {
			display: none;
			padding: 1em 0 0 1.15em;
			p {
				line-height: 150%;
			}
			h3 {
				color: #000;
				font-size: 1.3em;
				font-weight: 500;
				margin: 0 0 1em 0;
			}
		}
		input:checked ~ .answer {
			display: block;
		}
		h3 {
			margin: 0 0 0 0;
			color: $primary;
		}
	}
}

.faq-form {
	margin-top: 4em;
	max-width: 480px;
	textarea {
		width: 100%;
		height: 12em;
		padding: .25em;
		border: 1px solid #bbb;
		border-radius: $radius;
		box-shadow: inset 1px 2px 3px rgba(0,0,0,.15);
	}
	p {
		display: flex;
		align-items: center;
		margin: 0;
		padding: .15em 0;
		button {
			@include button_default();
		}
		span {
			margin-left: auto;
			font-size: .85em;
			color: #555;
			a {
				color: inherit;
			}
		}
	}
}






.recipe-overwrite-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .85em;
    padding: 2px 4px;
    button {
        width: auto;
        margin: 0 0 0 auto;
        cursor: pointer;
        background: none;
        color: $primary;
        padding: 3px 5px;
        border-radius: 2px;
        border: none;
        &:hover {
            background: $primary;
            color: #fff;
        }
    }
    &:hover {
        background: #ddd;
    }
}





.panel-purchase {
	.why {
		line-height: 150%;
		>div {
			display: flex;
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}
			i {
				display: inline-block;
				margin-top: 2px;
				color: $primary;
			}
			p {
				margin: 0 0 0 1em;
				font-weight: bold;
			}
		}
	}
    form {
        .row {
            display: flex;
            justify-content: space-between;
            p {
                flex-basis: calc(50% - .5em);
            }
        }
        p {
            margin: 0 0 1em 0;
            span {
                font-size: 12px;
                font-weight: bold;
                color: $primary;
            }
        }
        input[type="text"], textarea {
            display: block;
            margin-top: 4px;
            width: 100%;
            padding: 4px 5px;
            border: 1px solid #ccc;
            border-radius: 3px;
        }
        textarea {
            height: 7em;
        }
        button {
            @include button_default();
            background: $primary;
            color: #fff;
            &:hover {
                background: darken($primary, 8%);
            }
        }
        a {
            color: inherit;
        }
        .info {
            font-size: .85em;
        }
    }
}



.public-profile-link {
	background: $primary;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	padding: .75em 2em;
	border-radius: $radius*2;
	text-decoration: none;
	margin-left: auto;
}

.energy-output-row {
	label.help {
		cursor: pointer;
		input {
			display: none;
		}
		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #efefef;
			border-radius: 100px;
			border: 1px solid #ccc;
			font-size: .85em;
			width: 1.5em;
			height: 1.5em;
			margin-left: .5em;
		}
		&:hover {
			span {
				background: #fff
			}
		}
	}
}


.day-overview-wrap {
	.day-overview-meal {
		h2 {
			font-size: 1.4em;
			border-top: 1px solid #ccc;
			padding-top: 1em;
			margin-bottom: 1em;
			color: $primary;
			font-weight: 100;
			>span {
				font-size: .8rem;
			}
		}	
	}
}
























