
.page-recipes {
    flex-wrap: wrap;
    padding-top: 0;
    .filters {
        width: 100%;
        background: none;
        box-shadow: none;
        // max-width: 960px;
        // margin: 1em auto 0 auto;
        input[type="text"] {
            font-size: 1.25em;
        }
    }

    .list {
    }    
}
