header {
    color: #000;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    position: relative;

    .container {
        display: flex;
        align-items: center;
        >a {
            position: relative;
            top: 2px;
            text-decoration: none;
            color: #000;
            font-weight: 900;
            svg {
                display: block;
                height: 24px;
                width: auto;
            }
        }

        >input[type="checkbox"], >label {
            display: none;
        }
    }

    nav {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
        padding: 0 0 0 2em;
        label {
            display: none;
        }
        a {
            display: inline-block;
            padding: .85em .5em;
            text-align: center;
            text-decoration: none;
            position: relative;
            color: #333;
            margin: 0 8px;
            border-top: 4px solid #fff;
            border-bottom: 4px solid #fff;
            &.active {
                border-bottom: 4px solid $primary !important;
                color: $primary;
                font-weight: bold;
            }
            &:hover {
                border-bottom: 4px solid #ccc;
            }
        }
        a:after {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
            margin-bottom: -1px;
        }        
        .btn-back {
            margin-left: auto;
        }
    }
    .nav-shadow {
        display: none;
    }
            
    aside {
        color: #fff;
        align-self: center;
        display: flex;
        align-items: center;
        margin-left: auto;

        >a {
            display: inline-block;
            margin-right: 1.5em;
            color: #777;
            &:hover {
                color: $primary;
            }
        }

        >a.ad {
            background: #eaa852;
            color: #fff;
            text-decoration: none;
            padding: 4px 6px;
        }

        .profile {
            position: relative;
            color: #777;
            input {
                display: none;
            }
            label {
                cursor: pointer;
                user-select: none;
                display: flex;
                align-items: center;
                background: #efefef;
                border-radius: 40px;
                span {
                    transition: all .15s ease-out;
                    padding: 0 .5em 0 .85em;
                }
            }
            img {
                display: block;
                width: 36px;
                height: 36px;
                border-radius: 40px;
                border-left: 1px solid #ddd;
            }
            b {
                font-weight: normal;
                display: inline-flex;
                font-size: 10px;
                width: 1.7em;
                height: 1.7em;
                align-items: center;
                justify-content: center;
                color: #fff;
                background: #f00;
                border-radius: 100px;
                letter-spacing: -1px;
            }
         
        }

        .profile-menu {
            display: none;
            position: absolute;
            right: 0;
            top: calc(100% + 2px);
            z-index: 10;
            border-radius: $radius;
            background: #fff;
            box-shadow: 0 8px 48px rgba(0,0,0,.2);
            a, p {
                white-space: nowrap;
                display: block;
                margin: 0;
                padding: 12px 24px 12px 16px;
                color: #444;
                span {
                    display: inline-block;
                    width: 1.5em;
                    color: #444;
                    font-size: .8em;
                }
            }
            a:first-child {
                border-radius: 4px 4px 0 0;
            }
            a:last-child {
                border-radius: 0 0 4px 4px;
            }
            a {
                text-decoration: none;
                &:hover {
                    color: $primary;
                }
            }
        }

        input:checked ~ .profile-menu {
            display: block;
            animation: slideUp .15s ease-out;
        }
        input:checked + label {
            span {
                transform: scaleY(-1);
            }
        }

    }

    @media screen and (max-width: 839px) {
        .container {
            padding-top: .5em;
            padding-bottom: .5em;
            >label {
                display: block;
                padding: .60em 1em;
                cursor: pointer;
                margin-left: auto;
                border-radius: 40px;
                color: $primary;
                background: $primary-lightest;
                border: 1px solid darken($primary-lightest, 5%);
                text-transform: uppercase;
                color: darken($primary, 20%);
            }
        }
        nav {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            transform: translateY(-100%);
            transition: none;
        }
        #cb-nav:checked + label {
            background: $primary;
            color: #fff;
        }
        #cb-nav:checked ~ nav {
            display: block;
            z-index: 1010;
            background: #fff;
            padding: 0;
            box-shadow: 0 12px 48px rgba(0,0,0,.35);
            transform: translateY(0%);
            transition: transform .2s ease-in-out;
            label {
                display: block;
                text-align: right;
                cursor: pointer;
                font-size: 1.5em;
                padding: .6667em 1em;
                &:hover {
                    color: $primary;
                }
            }
            a {
                display: block;
                border: none;
                margin: 0;
                padding: 1em 1.5em;
                text-align: right;
                &:hover {
                    background: $primary;
                    color: #fff;
                }
                &.active {
                    font-weight: bold;
                    border-bottom: none !important;
                    background: $primary-lightest;
                    color: #000 !important;
                }
            }
        }
        #cb-nav:checked ~ .nav-shadow {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.5);
            z-index: 1000;
        }
        aside {
            margin-left: 1em;
            .profile-menu {
                font-size: 1.2em;
            }
        }
    }


}

@keyframes slideUp {
    0% { transform: translateY(.3em); opacity: .4 }
    100% { transform: translate(0); opacity: 1 }
}

.heading {
    padding: .25rem 0 1.25rem 0;
    display: flex;
    align-items: center;
	h1 {
		font-size: 1.3em;
		font-weight: 700;
        display: flex;
        color: $primary;
		align-items: center;
		line-height: 100%;
		margin: 0;
		span {
			margin-right: 1em;
		}
	}
	h1 a, p a {
		white-space: nowrap;
		font-weight: 400;
		font-size: .85rem;
		border: 1px solid $primary;
		color: $primary;
		padding: .35rem 1rem .45rem 1rem;
		text-decoration: none;
		border-radius: $radius;
		margin-right: .5em;
		&:hover {
			background: $primary;
			color: #fff;
		}
    }
}
