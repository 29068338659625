.page-index {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0,0,0,.25);
    }
    .user {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 8em 1em;
        background: none;
        box-shadow: none;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            //background: linear-gradient(to bottom, rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%); 
        }
        article {
            position: relative;
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            background: rgba(255,255,255,.9);
            padding: 1em;
            border-radius: $radius;
            box-shadow: 0 20px 60px rgba(0,0,0,.25);
            max-width: 600px;
            .avatar {
                flex-basis: 60px;
                margin-right: 1em;
                div {
                    width: 60px;
                    height: 60px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 200px;
                }
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    border-radius: $radius;
                }
            }
            h2 {
                text-align: left;
                font-size: 1.6em;
                line-height: 130%;
                margin: 0;
                font-weight: 900;
                z-index: 2;
            }
            p {
                text-align: left;
                margin: .2em 0 0;
                z-index: 2;
                a {
                    color: inherit;
                    text-decoration: none;
                    border-bottom: 1px dotted #000;
                }
            }
            >a {
                position: absolute;
                right: 1em;
                top: 1em;
                background: #fff;
                font-size: .9em;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: .85em 1.25em;
                color: #444;
                border-radius: 4px;
                border: 1px solid #ddd;
                font-weight: bold;
                span {
                    margin-left: .5rem;
                    font-size: 1.2em;
                    color: #f32;
                }
            }
        }
        .links {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 0 -1em;
            a {
                flex-basis: calc(50% - 1em);
                margin: 1em 0 0 1em;
                @include button_default();
                text-align: center;
                line-height: 200%;
                padding: 1em 0 .5em 0;
                i {
                    display: block;
                    font-size: 2.5em;
                    margin-bottom: .5rem;
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 639px) {
            .links {
                padding: 1em;
                a {
                    flex-basis: 100%;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 569px) {
            padding: 4em 1em;
            article {
                flex-wrap: wrap;
                .avatar {
                    flex-basis: 100%;
                    img {
                        max-width: 80px;
                    }
                }
            }
        }
    }
    .build-info {
        position: relative;
        z-index: 3;
        p {
            margin: 0;
            font-size: 12px;
            background: rgba(255,255,255,.6);
            color: #000;
            padding: 3px 5px;
            border-radius: 3px;
        }
    }
}

.index-tab {
    margin: 1em auto;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ddd;
    border-bottom: none;
    td {
        padding: .25em 1em;
        border-bottom: 1px solid #ddd;
        background: #fff;
        text-align: left;
    }
    td:nth-child(2), td:last-child {
        text-align: right;
    }
}