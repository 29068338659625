.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    .container {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.page-bg {
    background: #efefef;
}

.page-header {
    text-align: center;
    padding: 1.25em 0 .75em 0;
    background: #f7f7f7;
    position: relative;
    h1 {
        font-size: 2.4em;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 100%;
        color: #404040;
        position: relative;
        aside {
            position: absolute;
            right: 1em;
            bottom: 0;
            font-size: .9rem;
            font-weight: normal;
            text-transform: none;
            a {
                padding-left: 1em;
                display: flex;
                align-items: center;
                background: $primary;
                color: #fff;
                text-decoration: none;
                line-height: 100%;
                border: 1px solid $primary;
                border-radius: $radius;
                span {
                    border-radius: 0 $radius $radius 0;
                    display: block;
                    margin-left: 1em;
                    padding: .75em 1em;
                    background: #fff;
                    color: $primary;
                }
            }
        }
    }
    .page-header-row {
        display: flex;
        align-items: center;
        h1 {
            font-size: 1.8em;
            white-space: nowrap;
            small {
                display: block;
                font-size: 1rem;
            }
        }
        .header-group {
            text-align: left;
        }
        .header-group-buttons {
            display: flex;
            align-items: center;
            a {
                background: $primary;
                color: #fff;
                padding: .5em .85em;
                font-size: 12px;
                border-radius: 4px;
                text-decoration: none;
                &:not(:first-child) {
                    margin-left: .5em;
                }
            }
        }
        aside {
            margin-left: auto;
			a, label {
				@include button_default();
				display: inline-block;
				padding: .5em 1em;
				font-size: .9em;
				margin-right: .5em;
			}
            .page-header-nav-label {
                display: none;
            }
            .btn-plain {
				color: #444;
                background: #fff;
                border: 1px solid #ddd;
				&:hover {
					background: $primary-lightest;
					color: #000;
				}
				svg {
                    height: 1em;
                    width: auto;
				}
            }    
            input[type="checkbox"] {
                display: none;
            }        
        }
    }

    #cb-page-header-nav {
        display: none;
    }

    .page-header-nav {
        display: block;
        a {
            margin: .25em 0;
        }
    }

    @media screen and (max-width: 959px) {
        .page-header-row {
            h1 {
                font-size: 1.5em;
            }
            aside {
                .page-header-nav-label {
                    display: inline-block;
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .page-header-nav {
            display: none;
        }
        #cb-page-header-nav:checked + .page-header-nav {
            display: block;
            position: absolute;
            right: 1em;
            top: calc(100% - .65rem);
            z-index: 110;
            background: #fff;
            box-shadow: 0 12px 40px rgba(0,0,0,.3);
            padding: 0 .5em;
            width: 100%;
            max-width: 260px;
            a, label {
                display: block;
                margin: .5em 0;
            }
        }
    
    }
}

.page-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    font-size: 1.1em;
    a, label {
        position: relative;
        cursor: pointer;
        top: 1px;
        z-index: 100;
        display: inline-block;
        font-weight: 400;
        padding: .9em 2.75em;
        border: 1px solid #cfcfcf;
        border-right: none;
        background: #ebebeb;
        color: #8c8c8c;
    }
    a.active, label.active {
        background: #fff;
        color: #444;
        font-weight: 600;
        border-bottom: 1px solid #fff;
    }
    a:not(.active):hover, label:not(.active):hover {
        background: #f1f1f1;
        color: #6c6c6c;
    }
    a:first-child, label:first-child {
        border-radius: 10px 0 0 0;
    }
    a:last-child, label:last-child {
        border-radius: 0 10px 0 0;
        border-right: 1px solid #cfcfcf;
    }
    label input {
        display: none;
    }
}

.page-content {
    border-top: 1px solid #cfcfcf;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.page-content-noborder {
    border-top: none;
}