
.panel {
    flex: 1;
    >h2 {
        margin: 2em 0 1em 0;
        font-size: 1.2em;
        font-weight: 600;
        &:first-child {
            margin-top: 0;
        }
    }
}

.panel-padded {
    padding: 2em;
}
  
.panel-sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 2em 0;
    a {
        @include button_default();
        background: none;
        color: #444;
        font-size: .9em;
        padding: .85em 1em;
        text-align: left;
        margin-bottom: .5em;
        i {
            margin-right: .5em;
        }
        &:hover {
            background: #fff;
            color: #000;
        }
    }
}

.panel-evaluation {
    flex-grow: 0;
    flex-basis: 70px;
    margin-left: 1em;
    padding: 0;
    background: #fff;

    .row {
        display: flex;
        height: 100%;
        .icons {
            flex-basis: 70px;
            label {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.85em;
                height: 1.85em;
                margin: 1em auto .75em auto;
                font-size: 1.2em;
                border: 1px solid #ccc;
                border-radius: 100px;
                cursor: pointer;
            }
        }
        .quick-result {
            text-align: center;
            font-size: 11px;
            margin: 0;
            padding: .5em 0;
            border-bottom: 1px solid #ddd;
            line-height: 120%;
            strong {
                display: block;
                text-align: center;
            }
            span {
                display: block;
                padding: 2px 4px;
            }
        }
    
        .main {
            display: none;
            flex: 1 1;
            background: #fff;
            border-radius: 0 $radius $radius $radius;
            font-size: 12px;
            h4 {
                background: $primary-lightest;
                font-weight: bold;
                color: #000;
                padding: 3px 4px;
                font-size: .9em;
                margin: 1.5em -1rem .5em 0;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    
        .indicator {
            p {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 100%;
                background: $primary;
                margin: 1em auto;
                width: 40px;
                height: 40px;
                border-radius: 120px;
                position: relative;
                >i {
                    display: block;
                    margin: 0 auto;
                    font-size: 1.2em;
                    color: #fff;
                }			
            }
        }
        .indicator-error {
            p {
                background: #e00;
            }
        }
    }    
}
   

#cb-panel-evaluation:checked ~ .panel-evaluation, .panel-evaluation-opened {
    flex-basis: 100%;
    max-width: 320px;
    min-width: 320px;
	.row {
		.icons {
            border-radius: $radius 0 0 0;
            border-right: 1px solid #ddd;
            label {
                transform: scaleX(-1);
            }
        }
		.main {
			display: flex !important;
		}
	}
}

.panel-menu {
    flex-basis: 190px;
    flex-grow: 0;
}

.panel-recipe {
    flex: 1;
    margin-left: 1em;
}

.panel-horizontal {
    font-size: .9em;
    display: flex;
    align-items: center;
    .day-info {
        display: flex;
        padding: .25em 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 1.5em;
        h2 {
            font-size: 1.2em;
            margin: 0 0 2px 0;
        }
        p {
            font-size: 10px;
            margin: 0;
        }
    }
    aside {
//        margin-left: auto;
    }
    a {
        display: inline-flex;
        align-items: center;
        margin-right: .5em;
        background: $primary;
        color: #fff;
        padding: .65em 1em;
        border-radius: $radius;
        i {
            font-size: 1.25em;
            margin-right: .4rem;
        }
        &:hover {
            background: lighten($primary, 8%);
            color: #fff;
        }
    }
}

.input-recipe-name {
    flex: 1;
    margin-right: .5em;
    border: 1px solid #bbb;
    border-radius: $radius;
    padding: 3px 5px;
    font-weight: bold;
}
