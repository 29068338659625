.recipe-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
    padding: .5em 0;

    &:hover {
        background: #f7f7f7;
    }

	.picture {
		flex-basis: 60px;
        height: 60px;
        margin-right: 1em;
		background-position: center center;
		background-repeat: no-repeat;
        background-size: cover;
        border-radius: 200px;
    }

    .info {
        flex: 1;
        display: flex;
        .recipe-nutrition-data {
            margin-left: auto;
        }
    }

    .tags {
        font-size: .85em;
        margin: 4px 0;
    }

    .actions {
        font-size: 12px;
        margin-top: 2px;
        a {
            display: inline-block;
            flex-basis: 100%;
            margin: 4px 6px 4px 0;
            font-size: 12px;
            text-align: center;
            padding: 4px 6px;
            background: #fff;
            text-decoration: none;
            border: 1px solid $primary;
            color: $primary;
            border-radius: $radius;
            &:hover {
                background: $primary;
                color: #fff;
            }
        }
        a.warning {
            border: 1px solid #e00;
            color: #e00;
            &:hover {
                background: #e00;
                color: #fff;
            }
        }

    }

    .actions2 {
        flex-basis: 80px;
        flex-wrap: wrap;
        text-align: right;
        a {
            display: block;
            flex-basis: 100%;
            margin: 4px 0;
            font-size: 12px;
            text-align: center;
            padding: 4px;
            background: #fff;
            text-decoration: none;
            border: 1px solid $primary;
            color: $primary;
            border-radius: $radius;
        }
        a.warning {
            border: 1px solid #e00;
            color: #e00;
        }
    }

    div.sort {
        padding: 0 .25em;
        a {
            color: #999;
            margin: .25em;
            &:hover {
                color: #000;
            }
        }
    }

    .avatar {
        img {
            width: 64px;
            height: auto;
            display: block;
            border-radius: 3px;
        }
        padding-right: 1em;
    }

    .check {
        flex-basis: 1.75em;
        padding-left: 5px;
    }

    .title {
        flex: 1;
        padding-left: 1em;
        h3 {
            margin: 0;
            font-size: 1em;
            font-weight: 600;
            flex-basis: 240px;
            // cursor: pointer;
        }
    }

    .generic {
        padding: 0 1em;
    }

    .recipe-add-to-recipe-btn {
        position: relative;
        display: flex;
        background: #4eb32d;
        color: #fff;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        font-size: 1.7em;
        border-radius: 200px;
        &:hover {
            background: #5ec33d;
        }
        span {
            position: absolute;
            right: -.5em;
            top: -.5em;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: 1px solid #4eb32d;
            color: #4eb32d;
            width: 1.5em;
            height: 1.5em;
            border-radius: 100px;
        }
    }
}

.list > .recipe-item:hover {
    background: $primary-lightest !important;
}


.recipe-options {
    margin: .5em 0;
    padding: 0;
    label, a {
        display: inline-block;
        padding: 1px;
        font-size: 1em;
        border: none;
        color: #777;
        margin-right: 6px;
        cursor: pointer;
        &:hover {
        }  
    }
    a:hover {
        color: $primary;
    }
    label.label-favorite:hover {
        color: #0a0;
    }
    label.label-undesirable:hover {
        color: #c00;
    }
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]:checked + label.label-favorite {
        background: #0a0;
        color: #fff;
    }
    input[type="checkbox"]:checked + label.label-undesirable {
        background: #c00;
        color: #fff;
    }
}


.recipe-item-gallery-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: $padding;
    margin-left: -1em;
}

.recipe-item-gallery {
    display: flex;
    flex-basis: calc(33.33% - 1em);
    margin: 0 0 1em 1em;
    flex-direction: column;
//    align-items: center;
   
	.picture {
        cursor: pointer;
        flex-basis: 200px;
        width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
        background-size: cover;
    }
    .title {
        padding: 6px 0 0;
        font-size: .9em;
        h3 {
            margin: 0;
            font-size: 1em;
            font-weight: normal;
            flex-basis: 240px;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .recipe-item-gallery {
        font-size: .9em;
    }
}
@media screen and (max-width: 719px) {
    .recipe-item-gallery {
        flex-basis: calc(50% - 1em);
    }
}
@media screen and (max-width: 559px) {
    .recipe-item-gallery {
        flex-basis: calc(100% - 1em);
    }
}

.recipe-item-gallery-food {
    display: flex;
    flex-basis: calc(16.667% - 1em);
    margin: 0 0 1em 1em;
    flex-direction: column;
    align-items: center;
   
	.picture {
        cursor: pointer;
        flex-basis: 120px;
        width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
        background-size: contain;
        border: 10px solid #fff;
    }
    .title {
        padding: 6px 0 0;
        font-size: .9em;
        h3 {
            margin: 0;
            font-size: 1em;
            font-weight: normal;
            flex-basis: 240px;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
    }
}
.recipe-nutrition-data {
    display: flex;
    width: 560px;
    font-size: .85em;
    justify-content: center;
    padding-right: 2em;
    >div {
        display: inline-flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: calc((100% - 100px) / 5);
        padding-right: 1em;
        span {
            display: none;
        }
    }
    >div:last-child {
        flex-basis: 100px;
    }
}

.recipes-header {
    background: #f7f7f7;
    .recipe-item {
        font-size: .85em;
        text-transform: uppercase;
        cursor: default;
        border-top: none;
        border-bottom: none;
        // margin-bottom: 1em;
        >div {
            padding: 1em 0;
        }
        .avatar {
            width: calc(64px + 1em);
            padding-left: .5em;
        }
        .picture {
            display: flex;
            align-items: center;
            height: auto;
            padding: 1em 0;
        }
        .generic {
            padding: 0 1em;
        }
        h3 {
            font-weight: normal;
            color: #737373;
        }
        a {
            color: #737373;
        }
    }
    .recipe-nutrition-data {
        font-size: 1em;
        a {
            text-decoration: underline;
        }
    }
}

.recipe-ownership-info {
    font-size: 11px;
    margin: 4px 0 0;
    color: #555;
}

@media screen and (max-width: 829px) {
    .recipe-item {
        .info {
            flex-wrap: wrap;
        }
        .title {
            margin-bottom: .5em;
        }
    }
    .recipe-nutrition-data {
        width: 100%;
        flex-basis: 100%;
        >div {
            flex-wrap: wrap;
            justify-content: flex-start;
            font-size: .9em;
            span {
                display: block;
                flex-basis: 100%;
                text-align: left;
                font-size: .8em;
                color: #555;
            }
        }
    }
    .recipe-ownership-info {
        display: none;
    }
    .recipes-header {
        display: none;
    }
}
@media screen and (max-width: 639px) {
    .recipe-nutrition-data {
        width: 100%;
        flex-wrap: wrap;
        >div, >div:last-child {
            flex-basis: 33.33%;
            margin-bottom: .5em;
        }
    }
    .recipe-item {
        align-items: flex-start;
        .picture {
            flex-basis: 60px;
            height: 50px;
            margin-top: 4px;
        }
        .title {
            h3 {
                font-size: .9em;
            }
        }
    }
}










.recipe-detail {
    padding: .5em .75em;
    h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 2em;
        font-weight: bold;
        color: #000;
        margin: 0 0 .5em 0;
        span {
            font-size: 1rem;
            margin-left: auto;
            a {
                display: inline-block;
                text-decoration: none;
                color: $primary;
                border: 1px solid $primary;
                padding: .5em 1.25em;
                border-radius: $radius;
                &:hover {
                    background: $primary;
                    color: #fff;
                }
            }
        }
    }

    h3 {
        font-weight: bold;
        margin: 1.5em 0 .5em 0;
        &:first-child {
            margin-top: 0;
        }
    }


    .recipe-detail-description {
        display: flex;
        align-items: flex-start;
        .col-picture {
            flex: 1;
        }
        .picture {
            flex: 1;
            position: relative;
            margin: 0 0 1em 0;
            span {
                display: none;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-size: 4em;
                color: #fff;
                text-shadow: 0 0 24px rgba(0,0,0,.7);
            }
            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: $radius;
            }
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.25);

                }
                span {
                    display: block;
                }
            }
        
        }
        .picture-full {

        }
        >div {
            flex-basis: 300px;
            padding-left: 2em;
        }
    }

    
    .recipe-detail-row {
        display: flex;
        justify-content: space-between;
    }

    .recipe-detail-main {
        flex-basis: calc(100% - 320px - 2em);
    }

    .recipe-detail-side {
        flex-basis: 320px;
        h3 {
            color: $primary;
            font-size: 1.5em;
            margin: 1.2em 0 .3em 0;
            font-weight: normal;
            &:first-child {
                margin-top: 0;
            }
        }
        p {
            line-height: 150%;
        }
    }

    .recipe-detail-contents {
        display: flex;
    }
}


.recipe-detail-navbar {
    display: flex;
    align-items: center;
    margin: 1.5em 0;
    font-size: .9em;
    .tabs {
        margin-right: 1em;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                a {
                    display: inline-block;
                    padding: .75em .5em;
                    margin-right: $padding/2;
                    border-bottom: 2px solid #ddd;
                }
                a:hover {
                    border-bottom: 2px solid #bbb;
                }
                a.active {
                    border-bottom: 2px solid $primary;
                    background: #fff;
                }
            }
        }
    }
    .tabs {
        border-bottom: 1px solid #ccc;
        a {
            position: relative;
            top: 1px;
            z-index: 2;
            border: 1px solid #fff;
            border-bottom: 1px solid #ccc;
            padding: .75em 1em;
        }    
        a.active {
            border: 1px solid #ccc;
            border-bottom: 1px solid #fff;
        }
    }
    aside {
        margin-left: auto;
        a {
            @include button_default();
            margin-left: .5em;
            font-size: 1em;
        }
    }
}

.form-generic {
    width: 100%;
    max-width: 680px;
    h3 {
        display: flex;
        span {
            margin-left: auto;
            font-size: 1rem;
            padding-right: 1rem;
            a {
                @include button_default();
                font-size: .85em;
                background: none;
                color: $primary;
                border: 1px solid $primary;
            }
        }
    }
    table {
        width: 100%;
        max-width: 680px;
        td {
            padding: 4px 1em 4px 0;
            vertical-align: top;
        }
        td:first-child {
            width: 160px;
        }
        input[type="text"], select, textarea {
            width: 100%;
            padding: 4px 5px;
        }
        textarea {
            height: 10em;
        }
        
    }
}



.recipe-detail-tags-ingredients {
    font-size: .85em;
    margin-left: 1.5em;
    padding-left: 1.5em;
    border-left: 1px solid #ddd;
    >div {
        p {
            margin: 0 0 2px 0;
        }
    }
}

.recipe-save-changes {
    padding: 1em;
    text-align: center;
    a {
        display: inline-block;
        padding: .65em 1.25em;
        background: $primary;
        color: #fff;
        text-decoration: none;
        border-radius: $radius;
    }
    a.disabled {
        background: #aaa;
    }
}




.recipe-preparation-steps-chunk {
    margin: 1em 0;
    &:first-child {
        margin-top: 0;
    }
}

.recipe-preparation-step-text {
}
.recipe-preparation-step {
    display: flex;
}

.recipe-preparation-step .number {
    padding: 0 0 1em 0;
    font-size: 1em;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        background: $primary;
        opacity: .4;
    }
}
.recipe-preparation-step .number span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-lightest;
    position: relative;
    border: 1px solid $primary;
    z-index: 2;
    color: #000;
    font-weight: 600;
    font-size: .9em;
    line-height: 100%;
    width: 2em;
    height: 2em;
    border-radius: 100px;
}
.recipe-preparation-step .text {
    flex: 1 1;
    padding-left: 1em;
    padding-top: .15em;
    padding-bottom: .5em;
}
.recipe-preparation-step:last-child .number:before {
    display: none;
}
.recipe-preparation-step:last-child .text {
	padding-bottom: 0;
}



.recipe-tags {
    input[type="checkbox"] {
        display: none;
    }
    label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 3px 1em 3px 0;
        white-space: nowrap;
        padding: .35em .5em;
        border-radius: $radius;
        background: $primary-lightest;
        margin: 0 4px 4px 0;
        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1.5em;
            height: 1.5em;
            border: 1px solid $primary;
            border-radius: $radius;
            margin-right: .5em;
            font-size: .85em;
            background: #fff;
            i {
                color: #fff;
            }
        }
        aside {
            margin-left: .5em;
            font-size: .85em;
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 1.3em;
                height: 1.3em;
                margin-left: 3px;
                color: #333;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    input:checked + label {
        background: $primary;
        color: #fff;
        span {
            i {
                color: $primary;
            }
        }
    }
    .recipe-tags-new {
        margin: 2em 0 1em 0;
        display: flex;
        align-items: center;
        input {
            padding: .2em .3em;
        }
        input[type="text"] {
            flex: 1;
            margin-right: .5em;
        }
    }
}



.share-choose-profile {
    font-size: .9em;
    margin: 0 0 1.5em 0;
    display: flex;
    align-items: center;
    span {
    }
    label {
        display: inline-flex;
        align-items: center;
        margin-left: 1em;
        input {
            margin-right: .25em;
        }
    }
}

























