.plan {
	display: flex;
    flex: 1 1;
}

.plan-greeting {
    text-align: center;
    h1 {
        font-weight: 100;
        font-size: 2em;
    }
}

.plan-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.radio-plan-mobile-nav {
	display: none;
}

.plan-mobile-nav {
	flex-basis: 100%;
	display: none;
}

.plan-main {
    order: 2;
    flex-grow: 1;
    //flex: 1;
    // margin-left: 1em;
    display: flex;
    flex-direction: column;
}
.plan-side {
    order: 1;
    flex-basis: 270px;
    margin-right: 1em;
}

.plan-evaluation {
    display: flex;
    margin-left: 1em;
    flex-basis: 70px;
    order: 3;
}

@media screen and (max-width: 959px) {
	.plan-mobile-nav {
		display: flex;
		flex-basis: auto;
		align-items: center;
		justify-content: center;
		padding: 1em 0 0;
		margin: -1em -1em 0 -1em;
		label {
			display: block;
			padding: .85em 1.5em;
			background: none;
			color: #555;
            background: #e5e5e5;
			border-radius: $radius $radius 0 0;
			cursor: pointer;
			i {
				display: none;
				margin-right: .25em;
				color: $primary;				
			}
			&:not(:last-child) {
				margin-right: .5em;
			}
			&:hover {
                color: #000;
			}
		}
	}
	.plan-main {
		margin-left: 0;
	}
	.plan-side {
		display: none;
	}
	.plan {
		.panel-evaluation {
			display: none;
		}
	}

	// Make active nav button

	#cb-plan-side:checked ~ .plan-mobile-nav {
		label:nth-child(1) {
			background: #fff;
			color: #000;
			i {
				display: inline-block;
			}
		}
	}

	#cb-plan-main:checked ~ .plan-mobile-nav {
		label:nth-child(1) {
			background: #fff;
			color: #000;
			i {
				display: inline-block;
			}
		}
	}

	#cb-plan-evaluation:checked ~ .plan-mobile-nav {
		label:nth-child(2) {
			background: #fff;
			color: #000;
			i {
				display: inline-block;
			}
		}
	}

	// Show active nav pane

	#cb-plan-side:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: block;
				flex-basis: 100%;
			}
			.plan-main {
				display: none;
			}
			.panel-evaluation {
				display: none;
			}
		}
	}


	#cb-plan-main:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: none;
			}
			.plan-main {
				display: block;
			}
			.panel-evaluation {
				display: none;
			}
		}
	}

	#cb-plan-evaluation:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: none;
			}
			.plan-main {
				display: none;
			}
			.panel-evaluation {
				display: block;
				flex-basis: 100%;
				min-width: 320px;
                flex: 1;
                margin-left: 0;
				.row {
					.icons {
						display: none;
						border-radius: $radius 0 0 0;
						border-right: 1px solid #ddd;
						label {
							transform: scaleX(-1);
						}
					}
					.main {
						display: flex !important;
					}
				}
			}
		}
	}
}

.plan-side-buttons {
    a {
        display: block;
        color: $primary-dark;
        text-decoration: none;
        padding: .75em 1.5em;
        margin: 0 0 4px 0;
    }
}

.planner-top-nav {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1em 0;
    font-size: .85em;
    .btn-plain, label {
        @include button_default();
        font-size: 1em;
        background: #fff;
        color: #444;
        border: 1px solid #ddd;
        &:hover {
            background: $primary-lightest;
            color: #000;
        }
    }
    input[type="checkbox"] {
        display: none;
    }
    label {
        margin-left: .5em;
        svg {
            height: 1em;
            width: auto;            
        }
    }
}




.planner-switch {
    padding: 1em 0;
    position: relative;
    label {
        margin-left: 0;
    }
    .options {
        position: absolute;
        z-index: 10000;
        left: 0;
        top: calc(100% + 1px);
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fff;
        a {
            display: block;
            padding: .65em 1em;
            &:hover {
                background: #efefef;
            }
        }
    }
}


.planner-hours {
    display: flex;
    flex: 1 1;
	flex-direction: column;

	background: #fff;
	padding: 1em 1em;
	border-radius: $radius;

	.planner-hours-header {
		margin-bottom: 1em;
		h3 {
			margin: 1em 0 0;
			text-transform: capitalize;
			font-weight: 600;
		}
		.navbar {
			margin: 0;
			display: flex;
			align-items: center;
			input[type="checkbox"] {
				display: none;
			}
			a, label {
				@include button_default();
				display: inline-block;
				padding: .5em 1em;
				font-size: .9em;
				margin-right: .5em;
			}
			.btn-plain {
				display: inline-flex;
				align-items: center;
				background: none;
				color: #444;
				padding: .35em .6em;
				border: 1px solid #ddd;
				font-size: .85em;
				margin-right: 0;
				&:hover {
					background: $primary-lightest;
					color: #000;
				}
				svg {
					height: 14px;
					width: auto;
				}
			}
			aside {
				margin-left: auto;
				display: flex;
				align-items: center;
				a, label {
					&:not(:first-child) {
						margin-left: 4px;
					}
				}
			}
		}		
	}

    >h3 {
		display: flex;
		align-items: center;
        color: $primary;
        font-size: 1.2em;
        font-weight: 600;
        margin: 0 0 1em 0;
		text-transform: capitalize;
		>a {
			font-size: 12px;
			color: #777;
			font-weight: normal;
			text-transform: initial;
		}
		aside {
			margin-left: auto;
			font-size: 1rem;
			text-transform: none;
			a {
				@include button_default();
				display: inline-block;
				padding: .5em 1em;
				font-size: .9em;
				margin-left: .5em;
			}
		}
	}

	.hour {
		cursor: pointer;
		background: #fff;
		display: flex;
		flex-direction: column;
		position: relative;
		transition: all .15s;
		min-height: 40px;

		&:not(:first-child) {
			border-top: 1px solid #ddd;
		}

		>span {
			position: absolute;
			left: 0px;
			top: 0px;
			padding: 2px;
			color: #555;
			font-size: .85em;
			font-weight: 300;
        }
        

		&:hover {
            background: #ffd;
			>span {
				font-weight: bold;
			}
		}
	}
	.hour-short {
		//height: 34px;
		>span {
			color: #ccc;
		}
	}
}


.meal-item {
	display: flex;
	width: calc(100% - 116px);
	margin: 4px 16px 4px 100px;
	background: #fff;
	border: 1px solid #4eb32d;
	border-left: 10px solid #4eb32d;
	font-size: .9em;
	transition: all .15s;
	transform: translateY(0);
	.meal-item-body {
		display: flex;
		flex: 1;
	}            
	.meal-item-title {
		display: flex;
		flex-basis: 13em;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		color: #4eb32d;
		font-weight: 600;
		text-decoration: none;
		text-align: center;
		padding: .5em 1em;
		p {
			margin: 0 0 4px 0;
			font-size: 1.2em;
			white-space: nowrap;
		}
		p.title {
		}
		.time {
			display: block;
			font-size: 12px;
			font-weight: 700;
			color: #777;    
			.b-not-used {
				display: inline-block;
				background: rgba(0,0,0,.5);
				font-weight: bold;
				color: #fff;
				padding: 3px 5px;
				margin: 0 1px;
				border-radius: 3px;
			}
		}
		.meal-item-profiles {
			display: flex;
			align-items: center;
			font-weight: 300;
			font-size: 11px;
			margin-top: .5em;
			b  {
				font-weight: normal;
				padding: 3px 4px;
				border-radius: 2px;
				margin-right: 2px;
			}
		}					
	}
	.meal-item-details {
		padding: 10px 10px;
		display: flex;
		flex: 1 1;
		flex-direction: column;
		justify-content: center;
		p {
			margin: 0;
			&:first-child {
				font-size: 1.15em;
				margin-bottom: 4px;
			}
		}
		.meal-item-details-title {
			>span {
				display: block;
				font-size: 9px;
				text-transform: uppercase;
				color: #555;
			}
		}
		.title-row {
			display: flex;
			align-items: center;
			margin: 2px 0;
			.recipe-preview {
				width: 6em;
				height: 4em;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: $radius;
				margin-right: .5em;
			}
		}
	}
	.meal-item-buttons {
		margin-left: auto;
		align-items: center;
		display: flex;
		padding-left: .5em;
		padding-right: .5em;
		a {
			display: inline-block;
			padding: 5px;
			color: #ccc;
			text-decoration: none;
			cursor: pointer;
			&:hover {
				color: $primary;
			}
		}
	}
	&:hover {
		transform: translateY(-3px);
		box-shadow: 0 3px 6px rgba(0,0,0,.15);
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid $primary;
				}
			}
		}
	}
	@media screen and (max-width: 839px) {
		width: calc(100% - 66px);
		margin: 4px 16px 4px 50px;	
	}
	@media screen and (max-width: 639px) {
		.meal-item-body {
			flex-wrap: wrap;
			.meal-item-title {
				flex-basis: 100%;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				.title {
					margin: 0 .5em 0 0;
				}
				.meal-item-profiles {
					margin-left: .5em;
				}
			}
			.meal-item-details {
				flex-basis: 100%;
			}

		}
		width: calc(100% - 66px);
		margin: 4px 16px 4px 50px;	
	}
}

.meal-item-drink-regime {
	border: 1px solid #07f;
	border-left: 10px solid #07f;
	.meal-item-title {
		color: #07f;
	}
	&:hover {
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid #48f;
				}
			}
		}
	}	
}

.meal-item-standalone {
	width: 100%;
	margin-left: 0;
}

.meal-item-training {
	border: 1px solid #f94;
	border-left: 10px solid #f94;
	.meal-item-title {
		color: #f94;
	}
	&:hover {
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid #f94;
				}
			}
		}
	}
}

.plan-generator {
	h2 {
		font-size: 1.1em;
		color: $primary;
		font-size: 1.6em;
		text-align: center;
		font-weight: 400;
		margin: 1em 0;
	}
	.sub-hd {
		color: #888;
		font-size: .9em;
		margin: 2em 0 1em 0;
		color: $primary;
		font-weight: bold;
		&:first-child {
			margin-top: 0;
		}
	}
	.profiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.profile {
			min-width: 160px;
			padding: 1em;
			text-align: center;
			label {
				cursor: pointer;
				display: block;
				.picture {
				}
				.picture-box {
					margin: 0 auto;
					width: 120px;
					height: 120px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 300px;
				}
				h3 {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1em;
					font-weight: normal;
					margin: 1em 0 0;
					color: #999;
				}
				input {
					display: none;
				}
				span {
					display: inline-flex;
					color: $primary;
					margin-left: .5em;
				}
				.no {
					display: inline-flex;
					color: #999;
				}
				.yes {
					display: none;
				}
				input:checked + h3 {
					color: $primary;
					font-weight: bold;
					.no {
						display: none;
					}
					.yes {
						display: inline-flex;
					}
				}
			}
		}
	}
	.options-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		label {
			cursor: pointer;
			flex-basis: calc(33.33% - 1em);
			display: flex;
			align-items: center;
			padding: 0 .5em;
			font-size: .9em;
			margin: 0 0 .5em 0;
			span {
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				border: 2px solid #aaa;
				background: #fff;
				padding: 4px;
				border-radius: 50%;
				margin-right: .5em;
				flex-shrink: 0;
			}
			input {
				display: none;
			}
			input:checked + span {
				border: 2px solid $primary;
				background: $primary;
			}
			select {
				margin-left: auto;
				margin-right: 2em;
			}
		}
	}
	.options-row-4 {
		label {
			flex-basis: calc(25% - 1em);
		}
	}
	.options-row-3 {
		label {
			flex-basis: calc(33.33% - 1em);
		}
	}
	.options-row-2 {
		label {
			flex-basis: calc(50% - 1em);
		}
	}

	@media screen and (max-width: 719px) {
		.options-row label, .options-row-4 label {
			flex-basis: calc(50% - 1em);
		}
		.options-row label, .options-row-3 label {
			flex-basis: calc(50% - 1em);
		}
	}
	@media screen and (max-width: 479px) {
		.options-row label{
			flex-basis: calc(100% - 1em);
		}
	}

	.submit {
		padding: 2em 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			@include button_default();
			border: none;
		}
	}
}

.generator-form {
	table {
		margin: 1em 0;
		td {
			padding: 2px 1.5em 2px 0;
		}
	}
}


.generator-results-info {
	padding: 0 1rem;
	h2 {
		color: $primary;
		font-size: 1.8em;
		margin: 0 0 1rem 0;
	}
	table {
		font-size: .9em;
		td:first-child {
			padding-right: 1em;
		}
	}
}





.panel-evaluation.evaluation-recipe-editor {
    .row .main h4 {
        font-size: .7rem;
        padding: .7rem;
    }
}